import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useMatches } from 'react-router-dom';

const Breadcrumbs = () => {
  const matches = useMatches();

  const breadcrumbs = matches
    .filter((match) => (match.handle as { crumb?: () => string | JSX.Element })?.crumb) 
    .map((match) => ({
      crumb: (match.handle as { crumb: () => string | JSX.Element }).crumb, 
      pathname: match.pathname,
    }));

  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return isLast ? (
          <Typography color="textPrimary" key={breadcrumb.pathname}>
            {breadcrumb.crumb()}
          </Typography>
        ) : (
          <Link component={RouterLink} to={breadcrumb.pathname} key={breadcrumb.pathname}>
            {breadcrumb.crumb()}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;

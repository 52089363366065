import { useMutation } from '@tanstack/react-query';
import { useAlertStore } from 'components/common/alert/useAlertStore';
import { CreateCheckInOutRequest } from 'services/check-in-out/check-in-out.types';
import { createCheckInOut } from 'services/check-in-out/check-in-out.service';
import { queryClient } from 'index';
import { displayAlertError, displayAlertSuccess } from 'components/common/alert/alertHelper';

const useCheckInOut = () => {
  const alertDispatch = useAlertStore((state) => state.dispatch);

  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: async ({ type, mapId, fromDesk }: CreateCheckInOutRequest) =>
      await createCheckInOut({ type, mapId, fromDesk }),
    onSuccess: (data) => {
      displayAlertSuccess(alertDispatch, data);
      queryClient.setQueryData(['check-in-out'], data);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      const errors = err.errors || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formikErrors: any = {};

      displayAlertError(alertDispatch, err);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      errors.forEach((apiError: any) => {
        formikErrors[apiError.field] = apiError.message;
      });
    },
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
};

export default useCheckInOut;

import { ResourceType } from 'components/common/enums/resourceType';

export const setDefaultDeskId = (id: string | null, mapUrl: string | null) => {
  if (id) {
    localStorage.setItem('defaultDeskId', id);
  }

  if (mapUrl) {
    localStorage.setItem('defaultDeskMapUrl', mapUrl);
  }
};

export const getDefaultDeskId = () => {
  return localStorage.getItem('defaultDeskId') || '';
};

export const getDefaultDeskMapUrl = () => {
  return localStorage.getItem('defaultDeskMapUrl') || '';
};

export const getDefaultDeskMapName = () => {
  return localStorage.getItem('defaultDeskMapName') || '';
};

export const setDefaulParkingId = (id: string | null, mapUrl: string | null) => {
  if (id) {
    localStorage.setItem('defaultParkingId', id);
  }

  if (mapUrl) {
    localStorage.setItem('defaultParkingMapUrl', mapUrl);
  }
};

export const getDefaultParkingId = () => {
  return localStorage.getItem('defaultParkingId') || '';
};

export const getDefaultParkingMapUrl = () => {
  return localStorage.getItem('defaultParkingMapUrl') || '';
};

export const getDefaultParkingMapName = () => {
  return localStorage.getItem('defaultParkingMapName') || '';
};

export const getMapIdByType = (type: ResourceType) => {
  return type === ResourceType.FLOOR
    ? getDefaultDeskId()
    : getDefaultParkingId();
};

export const getMapUrlByType = (type: ResourceType) => {
  return type === ResourceType.FLOOR
    ? getDefaultDeskMapUrl()
    : getDefaultParkingMapUrl();
};

export const getMapNameByType = (type: ResourceType) => {
  return type === ResourceType.FLOOR
    ? getDefaultDeskMapName()
    : getDefaultParkingMapName();
};

export { ResourceType };

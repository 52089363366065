import { Components } from '@mui/material/styles';

const components: Components = {
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: '#f7f7f7',
        boxShadow: 'none',
        borderRight: 'none',
        position: 'unset',
        paddingLeft: '2rem',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        position: 'unset',
        backgroundColor: '#fff',
        boxShadow: 'none',
        marginBottom: '2rem',
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '@media (max-width: 600px)': {
          '& .hideOnMobile': {
            display: 'none',
          },
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: '40px',
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        paddingTop: '0px',
        paddingBottom: '5px',
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          '& .MuiListItemText-primary': {
            color: '#4baed7',
            backgroundColor: 'transparent',
          },
          '& .MuiListItemIcon-root': {
            color: '#4baed7',
            minWidth: '40px',
          },
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#4baed7',
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        color: '#666666',
        fontSize: '.875rem',
        fontStyle: 'normal',
        lineHeight: '24px',
        fontWeight: '700',
      },
    },
  },

  // Table
  MuiTableContainer: {
    styleOverrides: {
      root: {
        backgroundColor: '#fff',
      },
    },
  },

  MuiTab: {
    styleOverrides: {
      root: {
        color: '#b0b0b0',
        fontSize: '.875rem',
        fontStyle: 'normal',
        lineHeight: '19px',
      },
    },
  },

  MuiTableBody: {
    styleOverrides: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: '0',
          '&.highlighted-table .MuiTableRow-root:first-of-type': {
            backgroundColor: 'rgba(75, 174, 215, 0.09)',
          },
        },
      },
    },
  },

  MuiTableRow: {
    styleOverrides: {
      root: {
        '@media (max-width: 600px)': {
          display: 'block',
          borderBottom: '1px solid #e0e0e0',
        },
      },
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: {
        '@media (max-width: 600px)': {
          display: 'block',
          textAlign: 'left',
          position: 'relative',
          borderBottom: 'none',
          padding: '0 1rem 0 0',
          marginBottom: '0',
          '&:before': {
            content: 'attr(data-label)',
          },
        },
        '&:before': {
          position: 'absolute',
          left: 0,
          paddingLeft: '1rem',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          width: '45%',
        },
      },
      head: {
        fontWeight: 'bold',
        fontSize: '.85rem',
      },
    },
  },

  MuiAvatar: {
    styleOverrides: {
      root: {
        '@media (max-width: 600px)': {
          '&.hideOnMobile': {
            display: 'none',
          },
        },
      },
    },
  },

  //Button
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        color: '#333333',
        '&:hover&': {
          color: '#4baed7',
          backgroundColor: 'transparent', 
        },
        '&:select': {
          color: '#4baed7',
          backgroundColor: 'transparent', 
        },
        '&:active': {
          color: '#4baed7',
          backgroundColor: 'transparent', 
        },
      },
    },
  },
};

export default components;

import { IPublicClientApplication } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";
import { getAccessToken } from "./getAccessToken";
import { useQuery } from "@tanstack/react-query";

const useIsAdmin = (msalInstance: IPublicClientApplication) => {
    return useQuery<boolean>({
        queryKey: ['isAdmin'],
        queryFn: async () => {
            const accounts = msalInstance.getAllAccounts();
            const token = await getAccessToken(msalInstance, accounts);
            const decoded = jwtDecode<any>(token || '');
            const isAdmin = decoded.groups.includes(import.meta.env.VITE_ADMIN_GROUP_ID || '');
            return isAdmin;
        },
    });
};

export default useIsAdmin;
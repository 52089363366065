import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { msalInstance } from "index";
import React from "react";
import { Navigate } from "react-router-dom";
import useIsAdmin from "services/authorization/useIsAdmin";

export const AuthenticatedRoute = ({ element }: { element: React.ReactElement }) => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      {element}
    </MsalAuthenticationTemplate>
  );
};

export const AuthenticatedAdminRoute = ({ element }: { element: React.ReactElement }) => {
  const { data: isAdmin, isLoading } = useIsAdmin(msalInstance);

  if (isLoading) {
      return <p>Loading...</p>;
  }

  if (!isAdmin) {
      return <Navigate to={'/'} replace />;
  }

  return (<AuthenticatedRoute element={element}></AuthenticatedRoute>);
};
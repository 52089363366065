import { useQuery } from '@tanstack/react-query';
import { getStatusCheckInOut } from 'services/check-in-out/check-in-out.service';
import { StatusCheckInOutResponse } from 'services/check-in-out/check-in-out.types';

export const useStatusCheckInOut = () => {
  {
    return useQuery<StatusCheckInOutResponse>({
      queryKey: ['StatusCheckInOut'],
      queryFn: getStatusCheckInOut,
    });
  }
};

import React from 'react';
import { NavLink as NavLinkBase, NavLinkProps } from 'react-router-dom';

interface CustomNavLinkProps extends NavLinkProps {
  activeClassName?: string;
}

const NavLink = React.forwardRef<HTMLAnchorElement, CustomNavLinkProps>(
  ({ activeClassName = '', className, ...props }, ref) => (
    <NavLinkBase
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [
          className, 
          isActive ? activeClassName : null,
        ]
          .filter(Boolean) 
          .join(' ')
      }
    />
  )
);

export default NavLink;

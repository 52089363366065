import React, { useState } from 'react';
import { msalInstance } from 'index';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
  Container,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import logo from '../../../images/logo.png';
import { SignOutButtonHeader } from '../authorization/signOutButtonHeader';
import { useLocation } from 'react-router-dom';
import theme from 'theme';
import User from '../user';
import useIsAdmin from 'services/authorization/useIsAdmin';
import CheckInOutButton from 'components/features/check-in-out/CheckInOutButton';

interface HeaderProps {
  handleDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const location = useLocation();
  const { data: isAdmin, isLoading } = useIsAdmin(msalInstance);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar sx={{ backgroundColor: '#fff', paddingLeft: '0' }}>
      <Container>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', sm: 'left' },
            }}
          >
            <Box
              component={Link}
              to="/schedule/people"
              sx={{
                width: { xs: '130px', sm: '170px' },
                height: { xs: '65px' },
                overflow: 'hidden',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: '100%',
                }}
              />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3, ml: 3 }}>
              <Typography
                component={Link}
                to="/schedule/people"
                sx={{
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  fontSize: '.875rem',
                  color: location.pathname.includes('/schedule')
                    ? theme.palette.primary.main
                    : theme.palette.secondary.dark,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.primary.main,
                  },
                }}
              >
                Schedule
              </Typography>

              {!isLoading && isAdmin && (
                <Typography
                  component={Link}
                  to="/settings/floor-plan"
                  sx={{
                    textDecoration: 'none',
                    fontSize: '.85rem',
                    fontWeight: 'bold',
                    color: location.pathname.includes('/settings')
                      ? theme.palette.primary.main
                      : theme.palette.secondary.dark,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  Settings
                </Typography>
              )}
            </Box>
          </Typography>
          <Box
            sx={{
              marginRight: { xs: 0, md: 2 },
              marginBottom: { xs: 0, md: 0 },
            }}
          >
            <CheckInOutButton className="checkInButtonMobile" />
          </Box>
          <IconButton onClick={handleMenuClick} aria-label="account">
            <User className="hideOnMobile" />
          </IconButton>
        </Toolbar>
      </Container>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: 5 }}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <SignOutButtonHeader />
      </Menu>
    </AppBar>
  );
};

export default Header;

import React from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import NavLink from '../navlink';
import { SignOutButtonSideMenu } from '../authorization/signOutButtonSideMenu';

interface SidebarProps {
  items: Array<{ text: string; path: string; icon: JSX.Element }>;
  mobileOpen: boolean;
  handleDrawerClose: () => void;
  drawerWidth: number;
}

const Sidebar: React.FC<SidebarProps> = ({
  items,
  mobileOpen,
  handleDrawerClose,
  drawerWidth,
}) => {
  const drawer = (
    <Box>
      <List>
        {items.map((item) => (
          <ListItem disablePadding key={item.text}>
            <ListItemButton
              component={NavLink}
              to={item.path}
              activeClassName="Mui-selected"
              sx={{ pl: 0 }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider sx={{ display: { sm: 'none' } }} />
        <SignOutButtonSideMenu />
      </List>
    </Box>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;

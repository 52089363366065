import { createTheme } from "@mui/material/styles";
import palette from "../theme/pallete";
import typography from "../theme/typography";
import components from "../theme/components";

const theme = createTheme({
  palette,
  components,
  typography
});

export default theme;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Alert } from "./components/common/alert";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "./theme/globalStyle.css";
import '@fontsource/noto-sans';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { getTenantAccount, msalConfig } from "./services/authorization/authConfig";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const msalInstance = new PublicClientApplication(msalConfig);


msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  const tenantAccount = getTenantAccount(accounts);

  if (tenantAccount) {
    msalInstance.setActiveAccount(tenantAccount);
  }

  msalInstance.addEventCallback(async (event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  );

  root.render(
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Alert />
          <App pca={msalInstance} />
        </ThemeProvider>
      </React.StrictMode>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
  );
});

import {
  ReservationMapOverview,
  ReservationPeopleOverview,
  ReservationTableOverview,
} from 'services/reservation/reservation.types';

export const CONFIRMATION_TEXTS = {
  cancelReservation: {
    title: 'Cancel Reservation',
    contentText: 'Are you sure you want to cancel this reservation?',
  },
  deleteSpot: {
    title: 'Delete Spot',
    contentText: 'Are you sure you want to delete this spot?',
  },
};

export const typeOptions = [
  { value: 'Parking', label: 'Parking' },
  { value: 'Floor', label: 'Floor' },
];

export const checkInTypeOptions = [
  { value: 'Home', label: 'Home' },
  { value: 'Office', label: 'Office' },
];

export const getOptionLabel = (value: string) => {
  const option = checkInTypeOptions.find((opt) => opt.value === value);
  return option ? option.label : '';
};

export const getErrorText = (error: any) =>
  typeof error === 'string' ? error : '';

export const updateTableReservationById = (
  id: string,
  resources: ReservationTableOverview.Resource[]
): ReservationTableOverview.Resource[] => {
  return resources.map((resource) => {
    const updatedDays: {
      [key: string]: ReservationTableOverview.DayReservation | null;
    } = {};

    for (const [date, reservation] of Object.entries(resource.days)) {
      if (reservation?.reservationId === id) {
        updatedDays[date] = null;
      } else {
        updatedDays[date] = reservation;
      }
    }

    return {
      ...resource,
      days: updatedDays,
    };
  });
};

export const updatePeopleReservationById = (
  id: string,
  users: ReservationPeopleOverview.UserReservation[]
): ReservationPeopleOverview.UserReservation[] => {
  return users.map((user) => {
    const updatedDays: {
      [key: string]: ReservationPeopleOverview.DayReservation | null;
    } = {};

    for (const [date, reservation] of Object.entries(user.days)) {
      if (reservation?.reservationId === id) {
        updatedDays[date] = null;
      } else {
        updatedDays[date] = reservation;
      }
    }

    return {
      ...user,
      days: updatedDays,
    };
  });
};

export const updateMapReservationById = (
  id: string,
  resources: ReservationMapOverview.Resource[]
): ReservationMapOverview.Resource[] => {
  return resources.map((resource) => {
    if (resource?.reservation?.reservationId == id) resource.reservation = null;

    return resource;
  });
};

import React from 'react';
import { Box} from '@mui/material';

interface LoginLayoutProps {
  children: React.ReactNode;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
      <Box sx={{ flexGrow: 1 }}>
        {children}
      </Box>
  );
};

export default LoginLayout;

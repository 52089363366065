import { getAccessToken } from "./authorization/getAccessToken";
import { msalInstance } from "index";

const API_BASE_URL = import.meta.env.VITE_WEB_API || "";

const request = async (
  path: string,
  method: "GET" | "POST",
  requestData?: any,
  formData?: FormData,
  contentType = "application/json",
): Promise<any> => {
  const accounts = msalInstance.getAllAccounts();
  const token = await getAccessToken(msalInstance, accounts);
  const url = `${API_BASE_URL}/${path}`;

  const headers: Record<string, string> = {};

  // Only set Content-Type when not using FormData
  if (contentType && !formData) {
    headers["Content-Type"] = contentType;
  }

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const options: RequestInit = {
    method,
    headers
  };

  // Handle either JSON or FormData as body content
  if (requestData && !formData) {
    options.body = JSON.stringify(requestData);
  } else if (formData) {
    options.body = formData;
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      const error = await response.json();
      throw error;
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};

// Adjusted post method to detect FormData and set the correct headers
const post = async <T>(
  path: string,
  requestData: any,
  formData?: FormData,
  contentType: string = "application/json",
): Promise<T> => {
  return request(path, "POST", requestData, formData, formData ? undefined : contentType) as Promise<T>;
};

const get = async <T>(path: string): Promise<T> => {
  return request(path, "GET") as Promise<T>;
};

const getImage = async <T>(
  path: string,
  accessToken: string,
): Promise<string | null> => {
  const photoResponse = await fetch(path, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "image/jpeg",
    },
  });

  if (photoResponse.ok) {
    const imageBlob = await photoResponse.blob();
    const imageUrl = URL.createObjectURL(imageBlob);
    return imageUrl;
  }

  return null;
};

export { post, get, getImage };

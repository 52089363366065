import { useMsal } from "@azure/msal-react";
import { MenuItem } from "@mui/material";

export const SignOutButtonHeader = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return <MenuItem onClick={handleLogout}>Sign out</MenuItem>;
};

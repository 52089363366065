import {
  Chair,
  LocalParking,
  EmojiPeople,
  Map,
  AccessTime,
  Schedule,
} from '@mui/icons-material';


export const scheduleSidebarItems = [
  { text: 'People', icon: <EmojiPeople />, path: '/schedule/people' },
  { text: 'Desks', icon: <Chair />, path: '/schedule/desks' },
  { text: 'Parking', icon: <LocalParking />, path: '/schedule/parking' },
  { text: 'Timetable View', icon: <Schedule />, path: '/schedule/timeview' }
];

export const settingsSidebarItems = [
  { text: 'Floor plan', icon: <Map />, path: '/settings/floor-plan' },
  { text: 'Desks', icon: <Chair />, path: '/settings/desks' },
  { text: 'Parking', icon: <LocalParking />, path: '/settings/parking' },
  { text: 'Timeslots', icon: <AccessTime />, path: '/settings/timeslots' },
];

import React, { useState } from 'react';
import { Box, Button, CircularProgress, keyframes } from '@mui/material';

import CheckInOutDialog from './CheckInOutDialog';
import theme from 'theme';
import useCheckInOut from 'hooks/check-in-out/useCheckInOut';
import { getDefaultDeskId } from 'services/resources/defaultMap';
import { useStatusCheckInOut } from 'hooks/check-in-out/useStatusCheckInOut';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface CheckInOutButtonProps {
  className?: string;
}

const CheckInOutButton: React.FC<CheckInOutButtonProps> = ({ className }) => {
  const { data, isLoading, isError, refetch } = useStatusCheckInOut();
  const { mutateAsync, isPending } = useCheckInOut();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleButtonClick = async () => {
    if (!data?.checkIn) {
      setPopupOpen(true);
    } else {
      const mapId = getDefaultDeskId();
      const type = data?.type;
      await mutateAsync({ type, mapId, fromDesk: false });
    }
    refetch();
  };

  if (isLoading) return <CircularProgress />;
  if (isError) return <Box>Error loading status</Box>;

  const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }`;

  return (
    <Box>
      <Button
        className={className}
        onClick={handleButtonClick}
        variant={data?.checkIn ? 'outlined' : 'contained'}
        sx={{
          backgroundColor: data?.checkIn
            ? 'transparent'
            : theme.palette.primary.main,
          color: data?.checkIn
            ? theme.palette.warning.main
            : theme.palette.common.white,
          borderColor: data?.checkIn
            ? theme.palette.warning.main
            : 'transparent',
          fontWeight: 'bold',
          minWidth: { xs: '100%', md: '120px' },
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
        disabled={isPending}
      >
        {data?.checkIn ? (
          <>
            <AccessTimeIcon
              sx={{
                animation: `${spin} 2s linear infinite`,
                fontSize: '1.2rem',
              }}
            />
            Check Out
          </>
        ) : (
          'Check In'
        )}
      </Button>
      {isPopupOpen && <CheckInOutDialog onClose={() => setPopupOpen(false)} />}
    </Box>
  );
};

export default CheckInOutButton;

import { TypographyOptions } from "@mui/material/styles/createTypography";


const typography: TypographyOptions = {
  fontFamily: "'Noto Sans', sans-serif",
  h5: {
    fontWeight: 700,
  },
  body1: {
    fontSize: ".85rem",
    fontWeight: 500,
  },
};

export default typography;

import { AccountInfo, InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
import { getTenantAccount } from "./authConfig";

export const getAccessToken = async (msalInstance: IPublicClientApplication, accounts: AccountInfo[]) => {

    const tenantAccount = getTenantAccount(accounts);
    if (!tenantAccount)
        return;

    const request = {
        scopes: [import.meta.env.VITE_AZURE_SCOPE || ""],
        account: tenantAccount,
    };
    try {
        const response = await msalInstance.acquireTokenSilent(request);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await msalInstance.acquireTokenRedirect(request);
        } else {
            throw error;
        }
    }
};
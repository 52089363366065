import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { getTenantAccount, loginRequest } from "../../../services/authorization/authConfig";
import LoginLayout from "../../common/layout/LoginLayout";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import logo from "../../../images/logo.png";
import texture from "../../../images/login-texture.jpg";
import theme from "../../../theme";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../../services/user/user.service";
import { LoadingSpinner } from "../../common/loadingSpinner";
import {
  setDefaulParkingId,
  setDefaultDeskId,
} from "services/resources/defaultMap";

const Login: React.FC = () => {
  const { instance, accounts, inProgress } = useMsal();
  const tenantAccount = getTenantAccount(accounts);

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  useEffect(() => {
    if (isAuthenticated) {
      loginUser();
    }
  }, [isAuthenticated]);

  const loginUser = async () => {
    const response = await userLogin({
      name: tenantAccount?.name || '',
      userId: tenantAccount?.idTokenClaims?.oid || '',
      username: tenantAccount?.username || ''
    });
    setDefaultDeskId(response.deskId, response.deskUrl);
    setDefaulParkingId(response.parkingId, response.parkingUrl);

    navigate("schedule/people");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (accounts.length > 0 || inProgress === "login") {
    return <LoadingSpinner />;
  } else {
    return (
      <LoginLayout>
        <Grid>
          <Grid
            container
            sx={{ height: "65px", maxWidth: "1200px", margin: "auto" }}
          >
            <Box>
              <img
                src={logo}
                alt="Logo"
                style={{
                  height: "50px",
                  objectFit: "cover",
                  marginLeft: "2rem",
                  marginTop: "1rem",
                }}
              />
            </Box>
          </Grid>

          <Grid
            sx={{
              backgroundImage: `url(${texture})`,
              backgroundSize: { xs: "0", md: "66.6% 100%" },
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left center",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.neutral.dark,
                height: { xs: "auto", md: "100vh" },
                maxWidth: "1200px",
                margin: "auto",
              }}
            >
              <Grid size={{ xs: 12, md: 8 }}>
                <Box sx={{ p: 5, mt: { xs: "0", md: "-55px" } }}>
                  <Typography
                    variant={isMobile ? "h4" : "h2"}
                    sx={{ mb: { xs: "2rem" } }}
                  >
                    Welcome to Your Reservation App
                  </Typography>
                  <Typography variant={isMobile ? "h6" : "h5"}>
                    Effortlessly reserve your desk or parking spot with our
                    user-friendly platform, designed to streamline your workday!
                  </Typography>
                </Box>
              </Grid>

              <Grid
                size={{ xs: 12, md: 4 }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                padding={5}
                sx={{
                  height: { md: "100%" },
                  backgroundColor: { md: "white" },
                }}
              >
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  gutterBottom
                  sx={{ textAlign: "left" }}
                >
                  Please sign in with your Office 365 account
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleLogin}
                  sx={{
                    mt: 4,
                    background: theme.palette.primary.main,
                    color: theme.palette.neutral.contrastText,
                  }}
                >
                  Sign in with Office 365
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LoginLayout>
    );
  }
};

export default Login;

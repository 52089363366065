import { useQuery } from "@tanstack/react-query";
import { getProfilePicture } from "services/user/user.service";

export const useProfilePicture = (accessToken: string, userId: string) => {
  return useQuery<any>({
    queryKey: ["profilePicture", userId],
    queryFn: () => {
      return getProfilePicture(accessToken, userId)
    },
    enabled: !!accessToken,
    staleTime: Infinity,
  });
};
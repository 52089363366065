import { BaseResponse } from "services/base.types";

export const displayAlertSuccess = (alertDispatch: any, data: BaseResponse) => {

    if (!data.message)
        return;

    alertDispatch({
        type: "ALERT/SET_SUCCESS",
        payload: {
            successMessage: data.message,
        },
    });
}


export const displayAlertError = (alertDispatch: any, data: BaseResponse) => {
    if (!data.message)
        return;

    alertDispatch({
        type: "ALERT/SET_ERROR",
        payload: {
            errorMessage: data.message,
        },
    });
}
import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import FooterBar from "../footerBar";
import Header from "../headerBar";
import Sidebar from "../sideBar";
import Breadcrumbs from "../breadcrumps";

const drawerWidth = 200;

interface LayoutProps {
  children?: React.ReactNode;
  sidebarItems: Array<{ text: string; path: string; icon: JSX.Element }>;
}

const Layout: React.FC<LayoutProps> = ({ children, sidebarItems }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#f7f7f7",
      }}
    >
      <Header handleDrawerToggle={handleDrawerToggle} />
      <Container sx={{ display: { md: "flex" } }}>
        <Sidebar
          items={sidebarItems}
          mobileOpen={mobileOpen}
          handleDrawerClose={handleDrawerClose}
          drawerWidth={drawerWidth}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { md: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Breadcrumbs/>
          <Outlet />
        </Box>
      </Container>
      <FooterBar />
    </Box>
  );
};

export default Layout;

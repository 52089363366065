import { get, post } from '../httpHelper';
import {
  CreateCheckInOutRequest,
  CreateCheckInOutResponse,
  StatusCheckInOutResponse,
} from './check-in-out.types';

export async function createCheckInOut({
  type,
  mapId,
  fromDesk
}: CreateCheckInOutRequest): Promise<CreateCheckInOutResponse> {
  const url = `CheckInOut/create`;
  const payload = {
    type,
    mapId,
    fromDesk
  };
  return await post(url, payload);
}

export async function getStatusCheckInOut(): Promise<StatusCheckInOutResponse> {
  const url = `CheckInOut/check-in-out-status`;
  return await get(url);
}
